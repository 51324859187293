export const toolbarConfig = {
    options: [
        'inline',
        'blockType',
        'fontSize',
        'list',
        // 'textAlign',
        'colorPicker',
        'link',
        // 'embedded',
        'emoji',
        'image',
        'remove',
        'history',
    ],
    blockType: {
        inDropdown: true,
        options: [
            'Normal',
            'H1',
            'H2',
            'H3',
            'H4',
            'H5',
            'H6',
            'Blockquote',
            'Code',
        ],
        className: 'roboto-font',
        component: undefined,
        dropdownClassName: 'roboto-font',
    },
    embedded: {
        icon: require('../../assets/icons/code.svg'),
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        embedCallback: undefined,
        defaultSize: {
            height: 'auto',
            width: 'auto',
        },
    },
    emoji: {
        icon: require('../../assets/icons/emoji.svg'),
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis: [
            '😀',
            '😁',
            '😂',
            '😃',
            '😉',
            '😋',
            '😎',
            '😍',
            '😗',
            '🤗',
            '🤔',
            '😣',
            '😫',
            '😴',
            '😌',
            '🤓',
            '😛',
            '😜',
            '😠',
            '😇',
            '😷',
            '😈',
            '👻',
            '😺',
            '😸',
            '😹',
            '😻',
            '😼',
            '😽',
            '🙀',
            '🙈',
            '🙉',
            '🙊',
            '👼',
            '👮',
            '🕵',
            '💂',
            '👳',
            '🎅',
            '👸',
            '👰',
            '👲',
            '🙍',
            '🙇',
            '🚶',
            '🏃',
            '💃',
            '⛷',
            '🏂',
            '🏌',
            '🏄',
            '🚣',
            '🏊',
            '⛹',
            '🏋',
            '🚴',
            '👫',
            '💪',
            '👈',
            '👉',
            '👉',
            '👆',
            '🖕',
            '👇',
            '🖖',
            '🤘',
            '🖐',
            '👌',
            '👍',
            '👎',
            '✊',
            '👊',
            '👏',
            '🙌',
            '🙏',
            '🐵',
            '🐶',
            '🐇',
            '🐥',
            '🐸',
            '🐌',
            '🐛',
            '🐜',
            '🐝',
            '🍉',
            '🍄',
            '🍔',
            '🍤',
            '🍨',
            '🍪',
            '🎂',
            '🍰',
            '🍾',
            '🍷',
            '🍸',
            '🍺',
            '🌍',
            '🚑',
            '⏰',
            '🌙',
            '🌝',
            '🌞',
            '⭐',
            '🌟',
            '🌠',
            '🌨',
            '🌩',
            '⛄',
            '🔥',
            '🎄',
            '🎈',
            '🎉',
            '🎊',
            '🎁',
            '🎗',
            '🏀',
            '🏈',
            '🎲',
            '🔇',
            '🔈',
            '📣',
            '🔔',
            '🎵',
            '🎷',
            '💰',
            '🖊',
            '📅',
            '✅',
            '❎',
            '💯',
        ],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: 'roboto-font',
        component: undefined,
        dropdownClassName: 'roboto-font',
    },
    history: {
        options: ['undo', 'redo'],
        undo: {
            icon: require('../../assets/icons/undo.svg'),
            className: undefined,
        },
        redo: {
            icon: require('../../assets/icons/redo.svg'),
            className: undefined,
        },
    },
    image: {
        icon: require('../../assets/icons/image.svg'),
    },
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        bold: {
            icon: require('../../assets/icons/bold.svg'),
            className: undefined,
        },
        italic: {
            icon: require('../../assets/icons/italic.svg'),
            className: undefined,
        },
        underline: {
            icon: require('../../assets/icons/underline.svg'),
            className: undefined,
        },
        strikethrough: {
            icon: require('../../assets/icons/strikethrough.svg'),
            className: undefined,
        },
    },
    link: {
        showOpenOptionOnHover: false,
        defaultTargetOption: '_blank',
        options: ['link', 'unlink'],
        link: {
            icon: require('../../assets/icons/link.svg'),
            className: undefined,
        },
        unlink: {
            icon: require('../../assets/icons/unlink.svg'),
            className: undefined,
        },
    },
    list: {
        options: ['unordered', 'ordered'],
        unordered: {
            icon: require('../../assets/icons/unordered-list.svg'),
            className: undefined,
        },
        ordered: {
            icon: require('../../assets/icons/ordered-list.svg'),
            className: undefined,
        },
    },
    textAlign: {
        options: ['left', 'center', 'right', 'justify'],
        left: {
            icon: require('../../assets/icons/align-left.svg'),
            className: undefined,
        },
        center: {
            icon: require('../../assets/icons/align-center.svg'),
            className: undefined,
        },
        right: {
            icon: require('../../assets/icons/align-right.svg'),
            className: undefined,
        },
        justify: {
            icon: require('../../assets/icons/justify.svg'),
            className: undefined,
        },
    },
    remove: {
        icon: require('../../assets/icons/remove.svg'),
        className: undefined,
        component: undefined,
    },
}
